import Modal from "react-bootstrap/Modal";
import { Form, Row, Col } from "react-bootstrap";
import { useState, useMemo, useEffect } from "react";
import { useMutation, useQuery } from "react-query";
import { toast } from "react-toastify";
import { useFormik } from "formik";
import * as yup from "yup";
import Select from "react-select";

import { reportActions } from "../../utils/reactQueryActions";
import { fetchActionsUtil, allAcountDetailIDs } from "../../utils/helpers";
import { useAuth } from "../../hooks/useAuth";
import ModalLoader from "../utils/ModalLoader";
import ConfirmDialog from "../ConfirmDialogue";
import { useEffectOnce } from "../../utils/hooks";
import { toUpper } from "lodash";
import { useNavigate } from "react-router-dom";

const AddAccounts = (props) => {
  const [accountDetails, setAccountDetails] = useState([]);
  const [allAccoutTypes, setAllAccoutTypes] = useState([]);
  const [misTypeError, setMisTypeError] = useState("");
  const [accID, setAccID] = useState("");
  const navigate = useNavigate()

  const { backendUrl } = useAuth();

  const fetchDetailTypeMutation = useMutation(
    (name) =>
      fetchActionsUtil(`${backendUrl}/api/accounts/accountDesc/${name}`, "GET"),
    {
      onError: ({ message = "" }) => {
        toast.error(message);
      },
    }
  );

  const fetchGenerateAccountIdMutation = useMutation(
    (payload) =>
      fetchActionsUtil(
        `${backendUrl}/api/accounts/generate-account-id`,
        "POST",
        "",
        payload
      ),
    {
      onError: ({ message = "" }) => {
        toast.error(message);
      },
    }
  );

  const getAccountDetails = async (name, usage = "") => {
    fetchDetailTypeMutation.mutate(name, {
      onSuccess: ({ accountDes }) => {
        const data = [...new Set(accountDes.map((d) => d.AccountDesc))].map(
          (el) => ({
            value: el,
            label: el,
          })
        );
        setAccountDetails(data);
        if (usage === "once") return;
        if (name === "CASH AND CASH EQUIVALENT") {
          formik.setFieldValue("DetailType", "BANK");
        } else {
          formik.setFieldValue("DetailType", data[0]?.value);
        }
      },
    });

    // var random = Math.round(Math.random() * (max - min) + min);

    let rangers = allAcountDetailIDs.filter((item) => {
      if (item.data.some((d) => d === name)) {
        return [item.num1, item.num2];
      }
    });

    fetchGenerateAccountIdMutation.mutate(
      { num1: rangers[0].num1, num2: rangers[0].num2 },
      {
        onSuccess: (res) => {
          formik.setFieldValue("AccountID", res.id);
        },
      }
    );
  };

  const { data } = useQuery(
    ["GET_ACCOUNT_TYPES"],
    () => fetchActionsUtil(`${backendUrl}/api/accounts/accountTypes`, "GET"),
    {
      keepPreviousData: true,
    }
  );

  useMemo(() => {
    const mainData = data?.accountTypes?.map((d) => ({
      value: d.AccountType,
      label: d.AccountType,
    }));
    setAllAccoutTypes(mainData);
  }, [data]);

  const warehouseData = useMemo(() => {
    return props?.warehouse ? props?.warehouse : "";
  }, [props?.warehouse]);

  useEffectOnce(() => {
    // -------
    if (["imprest", "cash on hand", "bank"].includes(props?.usage)) {
      formik.setFieldValue("Type", "CASH AND CASH EQUIVALENT");
      formik.setFieldValue("DetailType", toUpper(props.usage));
      getAccountDetails("CASH AND CASH EQUIVALENT", "once");
    }
    if (props?.type) {
      formik.setFieldValue("Type", props?.type);
      getAccountDetails(props?.type, "once");
    }
  });

  useEffect(() => {
    if (props?.warehouse) {
      formik.setFieldValue("Type", "FIXED ASSET");
      formik.setFieldValue("DetailType", "STOCK");
      formik.setFieldValue("Description", warehouseData);
      formik.setFieldValue("Account_Desc", warehouseData);
      getAccountDetails("FIXED ASSET", "once");
    }
  }, [props?.warehouse]);

  const sendAccountMutation = useMutation(
    (payload) =>
      fetchActionsUtil(`${backendUrl}/api/accounts`, "POST", "", payload),
    {
      onSuccess: ({ message, accountCreated }) => {
        if (formik.values.addOpeningBalance) {
          return navigate(`/journal-entry`, {
            state: {
              expenses: [accountCreated]
            },
          });
        }
        formik.resetForm({
          values: {
            AccountID: "",
            Description: "",
            Account_Desc: "",
            Type: "",
            DetailType: "",
          },
        });
        toast.success(message);
        if (props?.refetch) props.refetch();
        props.setAccountReturn_WH && accID && props.setAccountReturn_WH(accID);
        if (props.accountCreated) props.accountCreated(accountCreated);
        props.onHide();
      },
      onError: ({ message = "" }) => {
        toast.error(message);
      },
    }
  );

  const formik = useFormik({
    initialValues: {
      AccountID: "",
      Description: "",
      Account_Desc: "",
      Type: "",
      DetailType: "",
      addOpeningBalance: false
    },
    validationSchema: yup.object().shape({
      AccountID: yup.string().required("Account ID is required"),
      Description: yup.string().required("Account Name is required"),
      Account_Desc: yup.string().required("Description is required"),
      Type: yup.string().required("Account Type is required"),
      DetailType: yup.string().required("Type Detail is required"),
    }),
    onSubmit: async (values) => {
      if (misTypeError) {
        return;
      }
      // console.log(values);
      setAccID(values.AccountID);

      if (
        await ConfirmDialog({
          title: "Create Account",
          description: `Are you sure you want to create account with these details:  ${values.Description
            }, ${values.Type} , ${values.DetailType}`,
        })
      ) {
        sendAccountMutation.mutate(values);
      }
    },
  });

  useMemo(() => {
    if (formik.values.AccountID !== "" && formik.values.Type !== "") {
      allAcountDetailIDs.every((el) => {
        if (
          el.data.some((d) => d === formik.values.Type) &&
          (parseInt(formik.values.AccountID) < el.num1 ||
            parseInt(formik.values.AccountID) > el.num2)
        ) {
          setMisTypeError(`Enter number from ${el.num1} to ${el.num2}`);
          return false;
        }
        setMisTypeError("");
        return true;
      });
    }
  }, [formik.values.AccountID, formik.values.Type]);

  const matches = useMemo(() => {
    const data = [];
    if (formik.values.Type) {
      allAcountDetailIDs.every((el) => {
        if (el.data.some((d) => d === formik.values.Type)) {
          data.push(el.num1, el.num2);
          return false;
        }
        return true;
      });
    }
    return data;
  }, [formik.values.Type]);

  return (
    <Modal
      onHide={props.onHide}
      show={props.show}
      backdropClassName={`global-backdrop`}
      aria-labelledby="contained-modal-title-vcenter"
      centered
      dialogClassName="my-style-modal"
      animation={false}
      enforceFocus={false}
      size="md"
    >
      <Modal.Header closeButton>
        <Modal.Title>
          <header>
            <h1>Create New Account</h1>
          </header>

          <p>Add New Account</p>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="container">
          <div className="px-md-4 m-auto">
            <form
              noValidate
              onSubmit={formik.handleSubmit}
              className="form-relative"
            >
              <Form.Group as={Row} className="mb-3" controlId="Account ID">
                <Form.Label column sm="4">
                  Account ID
                </Form.Label>
                <Col sm="8">
                  <Form.Control
                    name="AccountID"
                    value={formik.values.AccountID}
                    placeholder="Enter Account ID"
                    onChange={formik.handleChange}
                    type="number"
                  />
                </Col>
                {/* <Col sm="4">

                </Col> */}
              </Form.Group>

              <Form.Group as={Row} className="mb-3" controlId="Account ID">
                <Form.Label column sm="4">
                  Account Name*
                </Form.Label>
                <Col sm="8">
                  <Form.Control
                    name="Description"
                    value={formik.values.Description}
                    placeholder="Enter Account Name"
                    onChange={formik.handleChange}
                    disabled={warehouseData}
                  />
                </Col>
                {/* <Col sm="4">

                </Col> */}
              </Form.Group>

              <Form.Group as={Row} className="mb-3" controlId="Account ID">
                <Form.Label column sm="4">
                  Description
                </Form.Label>
                <Col sm="8">
                  <Form.Control
                    name="Account_Desc"
                    value={formik.values.Account_Desc}
                    placeholder="Enter Description"
                    onChange={formik.handleChange}
                  />
                </Col>
                {/* <Col sm="4">

                </Col> */}
              </Form.Group>

              <Form.Group as={Row} className="mb-3" controlId="Account ID">
                <Form.Label column sm="4">
                  Account Type
                </Form.Label>
                <Col sm="8">
                  <Select
                    classNamePrefix={"form-select"}
                    isSearchable={true}
                    value={allAccoutTypes?.find(
                      (el) => el.value === formik.values.Type
                    )}
                    onChange={({ value }) => {
                      formik.setFieldValue("Type", value);
                      getAccountDetails(value);
                    }}
                    options={allAccoutTypes}
                    isDisabled={
                      ["imprest", "cash on hand", "bank"].includes(
                        props?.usage
                      ) || props?.warehouse
                    }
                  />
                </Col>
                {/* <Col sm="4">

                </Col> */}
              </Form.Group>

              <Form.Group as={Row} className="mb-3" controlId="Account ID">
                <Form.Label column sm="4">
                  Type Detail
                </Form.Label>

                {!fetchDetailTypeMutation.isLoading ? (
                  <Col sm="8">
                    <Select
                      classNamePrefix={"form-select"}
                      isSearchable={true}
                      value={accountDetails?.find(
                        (el) => el.value === formik.values.DetailType
                      )}
                      onChange={({ value }) => {
                        formik.setFieldValue("DetailType", value);
                      }}
                      options={accountDetails}
                      isDisabled={["imprest", "cash on hand", "bank"].includes(
                        props?.usage
                      )}
                    />
                  </Col>
                ) : (
                  <Col
                    sm="6"
                    className="text-center my-auto fw-bold text-primary fs-6"
                  >
                    Loading...
                  </Col>
                )}

                {!props?.accountCreated && <Form.Label className="col-2 mt-4">
                  <Form.Switch
                    className="text-nowrap"
                    label="Add Opening Balance"
                    checked={formik.values.addOpeningBalance}
                    onChange={() =>
                      formik.setFieldValue("addOpeningBalance", !formik.values.addOpeningBalance)
                    }
                  />
                </Form.Label>}

                {/* <Col sm="4">

                </Col> */}
              </Form.Group>
              {formik.touched.AccountID && !!formik.errors.AccountID ? (
                <p className="custom-invalid-feedback">
                  {formik.errors.AccountID}
                </p>
              ) : null}
              {misTypeError && (
                <p className="custom-invalid-feedback">{misTypeError}</p>
              )}
              {formik.touched.Description && !!formik.errors.Description ? (
                <p className="custom-invalid-feedback">
                  {formik.errors.Description}
                </p>
              ) : null}
              {formik.touched.Account_Desc && !!formik.errors.Account_Desc ? (
                <p className="custom-invalid-feedback">
                  {formik.errors.Account_Desc}
                </p>
              ) : null}
              {formik.touched.Type && !!formik.errors.Type ? (
                <p className="custom-invalid-feedback">{formik.errors.Type}</p>
              ) : null}
              {formik.touched.DetailType && !!formik.errors.DetailType ? (
                <p className="custom-invalid-feedback">
                  {formik.errors.DetailType}
                </p>
              ) : null}
              {matches.length > 0 && (
                <div className="text-muted">
                  <p>
                    Number Range: {matches[0]} - {matches[1]}
                  </p>
                </div>
              )}
            </form>
          </div>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <button
          className="btn bg print mx-3 btn-lg"
          onClick={props.onHide}
        >
          Cancel
        </button>
        <button
          onClick={() => formik.submitForm()}
          className="btn btn-primary btn-lg"
        >
          Create Account
        </button>
      </Modal.Footer>
      <ModalLoader show={sendAccountMutation.isLoading} />
    </Modal>
  );
};

export default AddAccounts;
