import { format } from "date-fns";
import { Modal, Form, Button } from "react-bootstrap";
import { useQuery } from "react-query";
import { useLocation, useNavigate, useParams } from "react-router-dom";
// import { services } from "../../config";
import { queryActions } from "../../utils/reactQueryActions";
import { useFormik } from "formik";
import * as yup from "yup";
import { Fragment, useEffect, useMemo, useState } from "react";
import { useAuth } from "../../hooks/useAuth";
import eventBus from "../../utils/EventBus";
import CurrencyInput from "react-currency-input-field";
import NewFundAndInventoryModal from "./NewFundAndInventoryModal";
import { isInventoryOrFundRequest } from "../../utils/helpers";
import RequisitionService from "../../hooks/requisitionService";
import { isEmpty, cloneDeep } from "lodash";
import queryString from "query-string";
import Select from "react-select";
import NewCustomRequisitionModal from "./NewCustomRequisitionModal";
import { useEffectOnce, useIsAdmin } from "../../utils/hooks";
import ConfirmDialog from "../ConfirmDialogue";
import { useStoreActions, useStoreState } from "easy-peasy";

const requisitionFormTypes = [
  { label: "Select Type of Requisition", value: "" },
  { label: "Material Request", value: "Material Request" },
  { label: "Fund Request", value: "Fund Request" },
  { label: "Budget", value: "Budget" },
  // { label: "Sales Inventory Request", value: "Inventory" },
  { label: "Other", value: "Other" },
  { label: "Pending Service Invoice", value: "Pending Service Invoice" },
  { label: "Invoice (Vendor)", value: "Invoice (Vendor)" },
  {
    label: "User Action",
    value: "User Action",
  },
  {
    label: "User Action (Delete Vendor)",
    value: "User Action (Delete Vendor)",
  },
];

export default function NewRequisitionModal({
  location,
  setShowNewRequisitionModal,
  modalTitle = "New Request",
  defaultFormValues = {},
  handleCreatedRequisition,
  dataForPendingPermit,
  selectedJob = {},
  itemsRequested = [],
  vendorInvoices = [],
  preSelectedSignatory,
}) {
  const { user: authUser, backendUrl } = useAuth();
  const generalSettings = useStoreState((state) => state.generalSettings);
  const { createRequisitionMutation } = RequisitionService();
  // const queryClient = useQueryClient();
  const navigate = useNavigate();
  let { id } = useParams();
  const isAdmin = useIsAdmin();

  const [reciever, setReceiver] = useState();
  const locationQ = useLocation();
  const raisedItemRequisitionData = useStoreState(
    (store) => store.raisedItemRequisitionData
  );
  const setRaisedItemRequisitionData = useStoreActions(
    (actions) => actions.setRaisedItemRequisitionData
  );

  const query = new URLSearchParams(locationQ.search);
  const globalValue = query.get("global");

  const getDepartments = async () => {
    // await waitFor(5000);
    let response = await fetch(`${backendUrl}/api/users/departments`, {
      method: "GET",
      headers: {
        Accept: "Application/json",
        "Content-Type": "Application/json",
      },
      credentials: "include",
    });

    if (!response.ok) {
      response = await response.json();
      throw new Error(response.message);
    }

    const { data } = await response.json();
    data.departments = [
      { label: "Select Recipient's Department", value: "" },
      ...data.departments.map((el) => ({
        ...el,
        label: el.Department,
        value: el.Department,
      })),
    ];
    return data;
  };
  const { data: { departments } = { departments: [] } } = useQuery(
    [queryActions.GET_DEPARTMENTS],
    () => getDepartments(),
    {}
  );

  const fetchForms = async (queryParams) => {
    // await waitFor(5000);

    let response = await fetch(
      `${backendUrl}/api/forms?&${queryString.stringify({
        formType: "Organisation",
        approvalType: "Required,Fill and Approval Required",
        ...(!isAdmin
          ? {
            department: authUser.Department,
            jobGrade: authUser.jobGrade,
            staff: authUser.Staff_ID,
          }
          : {}),
      })}`,
      {
        method: "GET",
        headers: {
          Accept: "Application/json",
          "Content-Type": "Application/json",
        },
        credentials: "include",
      }
    );

    if (!response.ok) {
      response = await response.json();
      throw new Error(response.message);
    }

    const { data } = await response.json();

    return data;
  };
  const { data: formQuery } = useQuery(["SYSTEM_FORMS"], () => fetchForms(), {
    keepPreviousData: true,
  });

  const allForms = useMemo(() => {
    const createdForms = formQuery?.forms
      ? formQuery?.forms
        .filter((el) => el.enabled)
        .map((el) => ({
          ...el,
          label: el.Title,
          value: el.Title,
        }))
      : [];

    return [...requisitionFormTypes, ...createdForms];
  }, [formQuery?.forms]);

  useEffectOnce(() => {
    // console.log(defaultFormValues);
    formik.setValues({
      ...formik.values,
      ...cloneDeep(defaultFormValues),
    });
  });

  const formik = useFormik({
    initialValues: {
      title: "",
      type: "",
      cost: 0.0,
      description: "",
      department: "",
      recipient: "",
      jobGrade: "",
      comment: "",
      vendor: "",
      jobNumber: "",
      attachedRequestid: "",
      preSelectedSignatory: [],
      currency: generalSettings?.prevailingCurrency,
      conversionAmount: generalSettings.dollarInBaseCurrency,
    },
    validationSchema: yup.object().shape({
      title: yup.string().required(),
      description: yup.string(),
      type: yup.string().required(),
      cost: yup.number().default(0),
      department: yup.string().required(),
      // recipient: yup.string().required(),
      // vendor: yup.string().required(),
      comment: yup.string(),
    }),
    onSubmit: async (values) => {
      if (
        isEmpty(values.recipient) &&
        !(await ConfirmDialog({
          title: "Recipient not Selected",
          description: "Continue?",
        }))
      ) {
        return;
      }

      if (
        !(await ConfirmDialog({
          title: "Send for Approval",
          description: "Are you sure you want to send the Requisition ?",
        }))
      ) {
        return;
      }

      values = cloneDeep(values);
      values.amount = values.cost;
      values.requestto = values.recipient;
      values.regdate = format(new Date(), "yyyy-MM-dd");
      values.status = "Pending";

      if (isEmpty(values.comment)) {
        values.comment = "...";
      }
      if (isEmpty(values.description)) {
        values.description = "...";
      }

      delete values.cost;
      delete values.recipient;

      // console.log(values);

      createRequisitionMutation.mutate(
        {
          ...values,
          ...(dataForPendingPermit ? { dataForPendingPermit } : {}),
          ...(vendorInvoices
            ? {
              dataForInvoiceVendor: JSON.stringify(vendorInvoices),
              vendorInvoices,
            }
            : {}),
        },
        {
          onSuccess: (data) => {
            formik.resetForm();
            if (data.requisition) {
              eventBus.dispatch("REQUISITION_CREATED", data.requisition);
              if (handleCreatedRequisition)
                handleCreatedRequisition(data.requisition);
            }

            setShowNewRequisitionModal(false);
          },
          onError: ({ errors }) => {
            if (errors) formik.setErrors(errors);
          },
        }
      );
    },
  });

  const getUsersInDepartments = async (department) => {
    let response = await fetch(
      `${backendUrl}/api/users/by-department/${department}`,
      {
        method: "GET",
        headers: {
          Accept: "Application/json",
          "Content-Type": "Application/json",
        },
        credentials: "include",
      }
    );

    if (!response.ok) {
      response = await response.json();
      throw new Error(response.message);
    }

    const { data } = await response.json();
    data.users = [
      { label: "Select Recipient", value: "" },
      ...data.users.map((el) => ({
        ...el,
        label: el.Name,
        value: el.Staff_ID,
      })),
      // .filter((el) => el.Staff_ID !== authUser.Staff_ID),
    ];
    return data;
  };

  const {
    data: { users } = { users: [] },
    refetch: getUsers,
    isFetching: isLoadingUsers,
  } = useQuery(
    [queryActions.GET_USERS_IN_DEPARTMENTS, formik.values.department],
    () => getUsersInDepartments(formik.values.department),
    {
      enabled: false,
    }
  );

  const fetchVendors = async (department) => {
    let response = await fetch(`${backendUrl}/api/vendors`, {
      method: "GET",
      headers: {
        Accept: "Application/json",
        "Content-Type": "Application/json",
      },
      credentials: "include",
    });

    if (!response.ok) {
      response = await response.json();
      throw new Error(response.message);
    }

    const { data } = await response.json();
    data.vendors = [
      { label: "None", value: "" },
      ...data.vendors.map((el) => ({
        ...el,
        label: `  ${el.CompanyName} / ${el.Vendor_ID}`,
        value: `  ${el.CompanyName} / ${el.Vendor_ID}`,
      })),
    ];
    return data;
  };

  const {
    data: { vendors } = { vendors: [] },
    isFetching: isLoadingVendors,
  } = useQuery([queryActions.GET_VENDORS], () => fetchVendors(), {
    enabled: true,
  });

  useEffect(() => {
    if (formik.values.department) {
      getUsers();
    }
  }, [formik.values.department]);

  const customForm = useMemo(() => {
    return formQuery?.forms && formik.values.type
      ? formQuery?.forms.find((el) => el.Title === formik.values.type)
      : null;
  }, [formik.values.type, formQuery?.forms]);

  // GET FORM PERMISION =====================================================
  const getNumberSystems = async () => {
    let response = await fetch(`${backendUrl}/api/numbersystem`, {
      method: "GET",
      headers: {
        Accept: "Application/json",
        "Content-Type": "Application/json",
      },
      credentials: "include",
    });

    if (!response.ok) {
      response = await response.json();
      throw new Error(response.message);
    }

    const { data } = await response.json();
    if (data.numberSystems) {
      const permissions = JSON.parse(
        data.numberSystems.find(
          (el) => el?.documentType === formik.values?.type
        )?.selectedSignatory || "[]"
      );

      if (!isEmpty(permissions)) {
        setReceiver(permissions[1]);
        formik.setFieldValue("department", permissions[1].department || "All");
        formik.setFieldValue("recipient", permissions[1].staff);
        formik.setFieldValue("jobGrade", permissions[1].jobGrade);
      }
    }
    return data;
  };

  const { refetch: refetchPermissions } = useQuery(
    ["NUMBER_SYSTEMS", formik.values?.type],
    () => getNumberSystems(formik.values?.type),
    {
      keepPreviousData: true,
      //  staleTime: 1000 * 60 * 60 * 24,
    }
  );

  useEffect(() => {
    if (globalValue) {
      formik.setFieldValue("type", "Inventory");
      // formik.setFieldValue("");
      // raisedItemRequisitionData
      setShowNewRequisitionModal(true);
    } else {
      //  formik.setFieldValue("type", "");
      setRaisedItemRequisitionData({});
    }
  }, []);

  console.log(query);

  // ==================================================================

  return !isInventoryOrFundRequest(formik.values.type) &&
    isEmpty(customForm) ? (
    <Modal
      show={true}
      onHide={() => setShowNewRequisitionModal(false)}
      dialogClassName="requisition-details-modal"
      backdropClassName={`global-backdrop`}
      centered={true}
      animation={false}
      enforceFocus={false}
      backdrop="static"
    >
      <Modal.Header closeButton>
        <Modal.Title>
          <h1> {modalTitle}</h1>
        </Modal.Title>
      </Modal.Header>
      <Form noValidate onSubmit={formik.handleSubmit}>
        <Modal.Body className="approve-action">
          {" "}
          <Form.Group className="mb-3">
            <Form.Label>Type :</Form.Label>

            <Select
              classNamePrefix={"form-select"}
              value={allForms.find((el) => el.value === formik.values.type)}
              options={allForms}
              onChange={(selected) => {
                if (selected.value === "Invoice (Vendor)") {
                  if (generalSettings.vendorInvoiceRequiresApproval) {
                    return navigate("/vendor/create-vendors-invoice");
                  }
                  return;
                }
                formik.setFieldValue("type", selected?.value);
              }}
            />
            <Form.Control.Feedback type="invalid">
              {formik.errors.type}
            </Form.Control.Feedback>
          </Form.Group>
          {formik.values.type && (
            <div className="">
              <Form.Group className="mb-3">
                <Form.Label>Title :</Form.Label>
                <Form.Control
                  name="title"
                  placeholder="Enter a title"
                  value={formik.values.title}
                  onChange={formik.handleChange}
                  isInvalid={formik.touched.title && !!formik.errors.title}
                />

                <Form.Control.Feedback type="invalid">
                  {formik.errors.title}
                </Form.Control.Feedback>
              </Form.Group>

              <Form.Group className="mb-3">
                <Form.Label>Description :</Form.Label>
                <label className="border p-0 rounded pb-2 message-area d-block">
                  <Form.Control
                    name="description"
                    value={formik.values.description}
                    onChange={formik.handleChange}
                    as="textarea"
                    className="w-100 border-0 p-3"
                    placeholder="Enter your detail description"
                    rows={4}
                  />
                </label>
              </Form.Group>

              {formik.values.type &&
                !formik.values.type.includes("User Action") && (
                  <>
                    <Form.Group className="mb-3">
                      <Form.Label>
                        Cost ({formik.values?.currency}) :{" "}
                      </Form.Label>
                      <CurrencyInput
                        allowNegativeValue={false}
                        className="form-control"
                        name="cost"
                        value={formik.values.cost}
                        onValueChange={(value, name) =>
                          formik.setFieldValue(name, value, true)
                        }
                        isInvalid={formik.touched.cost && !!formik.errors.cost}
                      />
                      <Form.Control.Feedback type="invalid">
                        {formik.errors.cost}
                      </Form.Control.Feedback>
                    </Form.Group>
                  </>
                )}

              {modalTitle !== "Send for Approval" ||
                defaultFormValues?.vendor ? (
                <Form.Group className={`mb-3 ${["Pending Service Invoice"].includes(formik.values.type) ? "d-none" : ""}`}>
                  <Form.Label>Vendor :</Form.Label>
                  <Select
                    classNamePrefix={"form-select"}
                    name="vendor"
                    options={vendors}
                    value={vendors.find(
                      (el) => el.value === formik.values.vendor
                    )}
                    onChange={(selected) =>
                      formik.setFieldValue("vendor", selected?.value)
                    }
                  />
                </Form.Group>
              ) : null}

              <div className="row gap-3">
                <Form.Group className="col mb-3">
                  <Form.Label>Department :</Form.Label>
                  <Select
                    classNamePrefix={"form-select"}
                    name="department"
                    options={departments}
                    value={departments.find(
                      (el) => el.value === formik.values.department
                    )}
                    onChange={(selected) =>
                      formik.setFieldValue("department", selected?.value)
                    }
                    isDisabled={reciever?.department}
                  />
                  {formik.touched.department && formik.errors.department ? (
                    <span className="text-danger mt-2">
                      {formik.errors.department}
                    </span>
                  ) : null}
                </Form.Group>

                <Form.Group className="col mb-3">
                  <Form.Label>Recipient :</Form.Label>
                  <Select
                    classNamePrefix={"form-select"}
                    name="recipient"
                    options={users}
                    value={users.find(
                      (el) => el.value === formik.values.recipient
                    )}
                    onChange={(selected) =>
                      formik.setFieldValue("recipient", selected?.value)
                    }
                    isDisabled={isLoadingUsers || reciever?.staff}
                  />

                  {formik.touched.recipient && formik.errors.recipient ? (
                    <span className="text-danger mt-2">
                      {formik.errors.recipient}
                    </span>
                  ) : null}
                </Form.Group>
              </div>

              <Form.Group className="mb-3">
                <Form.Label>Comment :</Form.Label>
                <label className="border p-0 rounded pb-2 message-area d-block">
                  <Form.Control
                    name="comment"
                    value={formik.values.comment}
                    onChange={formik.handleChange}
                    as="textarea"
                    className="w-100 border-0 p-3"
                    placeholder="Enter a comment"
                    rows={4}
                  />
                </label>
              </Form.Group>
            </div>
          )}
        </Modal.Body>
        <Modal.Footer>
          <span />
          <div className="proceed-actions">
            <Button
              disabled={createRequisitionMutation.isLoading}
              onClick={() => setShowNewRequisitionModal(false)}
              type="button"
              variant="white"
              className="border bg-white"
            >
              Cancel
            </Button>
            <Button
              disabled={createRequisitionMutation.isLoading}
              type="submit"
              variant="primary"
            >
              {createRequisitionMutation.isLoading
                ? "Please wait..."
                : "Send request"}
            </Button>
          </div>
        </Modal.Footer>
      </Form>
    </Modal >
  ) : !isEmpty(customForm) ? (
    <NewCustomRequisitionModal
      template={customForm.Template}
      setShowNewCustomRequisitionModal={setShowNewRequisitionModal}
      setTemplate={() => { }}
      FormID={customForm.id}
      handleCreatedRequisition={handleCreatedRequisition}
    />
  ) : (
    <NewFundAndInventoryModal
      setShowNewRequisitionModal={setShowNewRequisitionModal}
      previousFormValues={formik.values}
      selectedJob={selectedJob}
      handleCreatedRequisition={handleCreatedRequisition}
      itemsRequested={itemsRequested}
    />
  );
}
